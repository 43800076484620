import React, { useEffect, useState } from "react";
import loginBg from "../assets/images/login-bg.png";
import { useForm } from "react-hook-form";
import Links from "./Links";
import { useNavigate, useSearchParams } from "react-router-dom";
import _ from "lodash";
import { Modal } from "antd";

const Login = () => {
    const navigate = useNavigate();
    const [urlParams] = useSearchParams();

    const [showError, setShowError] = useState("");
    const [passwordOpen, setPasswordOpen] = useState(false);
    const [otpError, setOtpError] = useState("");
    const [passwordReset, setPasswordReset] = useState(false);
    const [samePasswordError, setSamePasswordError] = useState(false);
    const [loginOTP, setLoginOTP] = useState(false);
    const [loginOTPModal, setLoginOTPModal] = useState(false);
    const [verifyingOTP, setVerifyingOTP] = useState(false);
    const [loginLoader, setLoginLoader] = useState(false);

    const { register, handleSubmit, formState: { errors }, trigger, getValues } = useForm();
    const { register: otpRegister, handleSubmit: otpSubmit } = useForm();
    const { register: resetPasswordRegister, handleSubmit: resetPasswordSubmit, getValues: resetPasswordGetValues } = useForm();

    useEffect(() => {
        let mounting = false;
        if (!mounting) {
            document.title = "Login - Saira Jobs";
        }
        return () => {
            mounting = true;
        };
    }, []);

    const authenticate = async (data) => {
        setShowError("");
        setLoginLoader(true);
        const u = new URLSearchParams(data).toString();
        if (loginOTP) {
            const loginURL = await fetch(`https://sairajobs.com/email/otp-login?${u}`);
            const loginProcessData = await loginURL.json();
            setLoginLoader(false);
            if (loginProcessData.status === "success") {
                setLoginOTPModal(true);
            } else {
                setShowError("Something went wrong! Please try again after sometime.");
            }
        } else {
            const loginURL = await fetch(`https://api.sairajobs.com/login?${u}`);
            const loginProcessData = await loginURL.json();
            setLoginLoader(false);
            if (loginProcessData.token) {
                localStorage.setItem("token", loginProcessData.token);
                let redirectUrl = "https://sairajobs.com/portal/";
                if (!_.isEmpty(urlParams.get("redirectTo"))) {
                    redirectUrl = `${urlParams.get("redirectTo")}`;
                }
                navigate(`/authenticate?redirectTo=${redirectUrl}`);
            } else {
                setShowError(loginProcessData.message);
            }
        }
    };

    const otpauth = (data) => {
        setVerifyingOTP(true);
        fetch(`https://api.sairajobs.com/verify-email?email=${getValues("email")}&type=verify&otp=${_.values(data).join("")}`)
            .then((res) => res.json())
            .then((res) => {
                setVerifyingOTP(false);
                if (res.result.token) {
                    localStorage.setItem("token", res.result.token);
                    let redirectUrl = "https://sairajobs.com/portal/";
                    if (!_.isEmpty(urlParams.get("redirectTo"))) {
                        redirectUrl = `${urlParams.get("redirectTo")}`;
                    }
                    navigate(`/authenticate?redirectTo=${redirectUrl}`);
                } else {
                    setShowError(res.result.message);
                }
            });
    };

    const resetPasswordSubmission = async (data) => {
        const submissionData = { ...data };
        delete submissionData.cpassword;
        const u = new URLSearchParams(submissionData).toString();
        const fetchURL = await fetch(`https://sairajobs.com/api/change-password?${u}`);
        const fetchData = await fetchURL.json();
        if (fetchData.matchedCount === 1 && fetchData.matchedCount === 1) {
            setPasswordOpen(false);
            setPasswordReset(false);
        }
        if (fetchData.matchedCount === 1 && fetchData.matchedCount === 0) {
            setSamePasswordError(true);
        }
    };

    const handlepaste = (e) => {
        const pastedData = e.clipboardData.getData("text/plain");
        if (_.size(pastedData) === 6 && /^\d*$/.test(pastedData)) {
            _.forEach(pastedData.split(""), function (val, index) {
                document.querySelectorAll(".input-outer-wrap input")[index].value = val;
                document.querySelectorAll(".input-outer-wrap input")[index].focus();
            });
        } else {
            return;
        }
    };

    const acceptOnlyNum = (e) => {
        const inputValue = e.target.value;
        if (!/^\d*$/.test(inputValue)) {
            e.target.value = "";
            return;
        }
    };

    const nextStep = (num, event) => {
        if (!["1", "2", "3", "4", "5", "6", "7", "8", "9", "0"].includes(event.key)) {
            return;
        }
        if (event.key === "Backspace" && num !== 1) {
            num = num === 0 ? 6 : num;
            document.querySelectorAll(".input-outer-wrap input")[num - 2].focus();
            document.querySelectorAll(".input-outer-wrap input")[num - 2].select();
            return;
        }
        if (event.key === "Backspace" && num === 1) {
            document.querySelectorAll(".input-outer-wrap input")[0].focus();
            document.querySelectorAll(".input-outer-wrap input")[0].select();
            return;
        }
        if (num === 0) {
            document.querySelector(".verifi-popup-wrap .btn").focus();
            return;
        }
        document.querySelectorAll(".input-outer-wrap input")[num].focus();
        document.querySelectorAll(".input-outer-wrap input")[num].select();
    };

    const verifyOTP = (data) => {
        setVerifyingOTP(true);
        fetch(`https://api.sairajobs.com/verify-email?email=${getValues("email")}&type=verify&otp=${_.values(data).join("")}`)
            .then((res) => res.json())
            .then((res) => {
                setVerifyingOTP(false);
                if (!res.result.success) {
                    setOtpError(res.result.message || "Please Provide a valid OTP");
                    setShowError(res.result.message);
                    return;
                }
                setPasswordReset(false);
                setVerifyingOTP(true);
                let redirectUrl = "https://sairajobs.com/portal/";
                localStorage.setItem("token", res.result.token);
                if (!_.isEmpty(urlParams.get("redirectTo"))) {
                    redirectUrl = `${urlParams.get("redirectTo")}`;
                }
                navigate(`/authenticate?redirectTo=${redirectUrl}`);
            });
    };

    const resetPassword = () => {
        trigger("email").then(async (res) => {
            if (res) {
                const emailValue = getValues("email");
                const loginURL = await fetch(`https://sairajobs.com/email/otp-login?email=${encodeURIComponent(emailValue)}`);
                const loginProcessData = await loginURL.json();
                setLoginLoader(false);
                if (loginProcessData.status === "success") {
                    setPasswordOpen(true);
                } else {
                    setShowError("Something went wrong! Please try again after sometime.");
                }
            }
        });
    };

    const resendOTP = async () => {
        const emailValue = getValues("email");
        const loginURL = await fetch(`https://sairajobs.com/email/otp-login?email=${encodeURIComponent(emailValue)}`);
        const loginProcessData = await loginURL.json();
        if (loginProcessData.status !== "success") {
            setShowError("Something went wrong! Please try again after sometime.");
        }
    };

    const otpLogin = () => {
        setLoginOTP((e) => !e);
    };

    // eslint-disable-next-line no-useless-escape
    const emailpattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,12})+$/;

    // OTP Modal Component with Timer and Proper Alignment
    const OTPModalContent = ({ onSubmit, isOpen, setIsOpen }) => {
        const [timer, setTimer] = useState(60);
        const [isTimerExpired, setIsTimerExpired] = useState(false);

        useEffect(() => {
            if (!isOpen) {
                setTimer(60);
                setIsTimerExpired(false);
                setOtpError(""); // Clear error when modal closes
                return;
            }
            if (timer > 0) {
                const interval = setInterval(() => {
                    setTimer((prev) => prev - 1);
                }, 1000);
                return () => clearInterval(interval);
            } else {
                setIsTimerExpired(true);
                setOtpError("Please Provide a valid OTP"); // Set error when timer expires
            }
        }, [timer, isOpen]);

        const handleResendOTP = async () => {
            setTimer(60);
            setIsTimerExpired(false);
            setOtpError(""); // Clear error on resend
            await resendOTP();
        };

        return (
            <form className="verifi-popup-wrap" id="verify" onSubmit={otpSubmit(onSubmit)}>
                <h2>Check Your Email!</h2>
                <p>
                    We have sent 6 digit confirmation code to your email.<br />
                    Please enter the code before it expires.
                </p>
                <div className="input-outer-wrap">
                    <div className="input-wrap">
                        <input
                            type="text"
                            {...otpRegister("opt1")}
                            className="input-num one"
                            maxLength={1}
                            onInput={(e) => acceptOnlyNum(e)}
                            onKeyUp={(e) => nextStep(1, e)}
                            onPaste={handlepaste}
                        />
                        <input
                            type="text"
                            {...otpRegister("opt2")}
                            className="input-num two"
                            maxLength={1}
                            onInput={(e) => acceptOnlyNum(e)}
                            onKeyUp={(e) => nextStep(2, e)}
                        />
                        <input
                            type="text"
                            {...otpRegister("opt3")}
                            className="input-num three"
                            maxLength={1}
                            onInput={(e) => acceptOnlyNum(e)}
                            onKeyUp={(e) => nextStep(3, e)}
                        />
                    </div>
                    <div className="txt"></div>
                    <div className="input-wrap">
                        <input
                            type="text"
                            {...otpRegister("opt4")}
                            className="input-num one"
                            maxLength={1}
                            onInput={(e) => acceptOnlyNum(e)}
                            onKeyUp={(e) => nextStep(4, e)}
                        />
                        <input
                            type="text"
                            {...otpRegister("opt5")}
                            className="input-num two"
                            maxLength={1}
                            onInput={(e) => acceptOnlyNum(e)}
                            onKeyUp={(e) => nextStep(5, e)}
                        />
                        <input
                            type="text"
                            {...otpRegister("opt6")}
                            className="input-num three"
                            maxLength={1}
                            onInput={(e) => acceptOnlyNum(e)}
                            onKeyUp={(e) => nextStep(0, e)}
                        />
                    </div>
                </div>
                <p>
                    <b>{isTimerExpired ? "Code expired" : `Resend Code in ${timer} Seconds`}</b>
                </p>
                <p>
                    Keep this window open while checking for your code.<br />
                    Remember to try your spam folder!
                </p>
                <div
                    className="inputHolder has-error"
                    style={{
                        minHeight: "1px",
                        marginBottom: "20px", // Add spacing between error and buttons
                        textAlign: "center", // Center-align the error message
                    }}
                >
                    <span
                        className="form-error"
                        aria-live="assertive"
                        role="alert"
                        style={{
                            display: "block", // Ensure the error takes its own line
                            color: "red", // Match the error color
                            fontSize: "14px", // Match the font size of other text
                            lineHeight: "1.5", // Improve readability
                        }}
                    >
                        {!_.isEmpty(otpError) && <>{otpError}</>}
                    </span>
                </div>
                <div
                    className="btn-wrap justify-content-center"
                    style={{
                        display: "flex",
                        gap: "10px", // Add spacing between buttons
                        justifyContent: "center", // Center-align buttons
                        marginTop: "20px", // Add spacing above buttons
                    }}
                >
                    <button
                        type="submit"
                        className="btn"
                        disabled={verifyingOTP || isTimerExpired}
                        style={{
                            padding: "10px 20px", // Ensure consistent button size
                            backgroundColor: "#1890ff", // Match the blue color of Verify button
                            color: "#fff",
                            border: "none",
                            borderRadius: "4px",
                            cursor: verifyingOTP || isTimerExpired ? "not-allowed" : "pointer",
                        }}
                    >
                        {verifyingOTP ? (
                            <svg viewBox="0 0 50 50" className="btn-loader-2">
                                <path
                                    d="M50 25C50 11.2 38.8 0 25 0S0 11.2 0 25h4.2C4.2 13.6 13.5 4.2 25 4.2S45.8 13.6 45.8 25H50z"
                                    style={{ fill: "#fff" }}
                                >
                                    <animateTransform
                                        accumulate="none"
                                        additive="replace"
                                        attributeName="transform"
                                        attributeType="XML"
                                        calcMode="linear"
                                        dur="1s"
                                        fill="remove"
                                        from="0 25 25"
                                        repeatCount="indefinite"
                                        restart="always"
                                        to="360 25 25"
                                        type="rotate"
                                    />
                                </path>
                            </svg>
                        ) : (
                            "VERIFY"
                        )}
                    </button>
                    {isTimerExpired && (
                        <button
                            type="button"
                            className="btn"
                            onClick={handleResendOTP}
                            style={{
                                padding: "10px 20px", // Match button size
                                backgroundColor: "#1890ff", // Match the blue color
                                color: "#fff",
                                border: "none",
                                borderRadius: "4px",
                                cursor: "pointer",
                            }}
                        >
                            RESEND OTP
                        </button>
                    )}
                    <button
                        type="button"
                        className="btn red secondary"
                        onClick={() => setIsOpen(false)}
                        style={{
                            padding: "10px 20px", // Match button size
                            backgroundColor: "transparent", // Match the Cancel button style
                            color: "red",
                            border: "1px solid red",
                            borderRadius: "4px",
                            cursor: "pointer",
                        }}
                    >
                        CANCEL
                    </button>
                </div>
            </form>
        );
    };

    return (
        <div className="login-wrap" style={{ backgroundImage: `url(${loginBg})` }}>
            <div className="content">
                <h2 className="title">Sign in and Begin Your Journey</h2>
                <div className="register">
                    Don't have an account? <Links target="/register" content="Register Now" />
                </div>
                <div className="find-job">
                    <form onSubmit={handleSubmit(authenticate)} className="noCol" aria-label="Login Form">
                        <div className={errors.email ? "inputHolder has-error" : "inputHolder"}>
                            <input
                                type="text"
                                placeholder=" "
                                className="txt"
                                id="usremail"
                                {...register("email", {
                                    required: "Please enter your email",
                                    pattern: { value: emailpattern, message: "Please provide a valid Email ID" },
                                })}
                                aria-label="Email ID"
                            />
                            <label htmlFor="usremail" className="tag" aria-hidden>Email ID</label>
                            <span className="form-error" aria-live="assertive" role="alert">
                                {errors.email && <>{errors.email.message}</>}
                            </span>
                        </div>
                        {!loginOTP && (
                            <div className={errors.password ? "inputHolder has-error" : "inputHolder"}>
                                <input
                                    type="password"
                                    placeholder=" "
                                    className="txt"
                                    id="usrpassword"
                                    {...register("password", {
                                        required: "Please enter your Password",
                                        minLength: { value: 8, message: "The password should have minimum 8 characters" },
                                    })}
                                    aria-label="Password"
                                />
                                <label htmlFor="usrpassword" className="tag" aria-hidden>Password</label>
                                <span className="form-error" aria-live="assertive" role="alert">
                                    {errors.password && <>{errors.password.message}</>}
                                </span>
                            </div>
                        )}
                        <div className="inputHolder has-error" style={{ minHeight: "1px" }}>
                            <span className="form-error" aria-live="assertive" role="alert">
                                {!_.isEmpty(showError) && <>{showError}</>}
                            </span>
                        </div>
                        <div className="inputHolder btnwrapper">
                            {!loginOTP && (
                                <button type="button" className="forgot" onClick={() => resetPassword()}>
                                    Forgot Password?
                                </button>
                            )}
                            <button type="submit" className="btn" disabled={loginLoader}>
                                {loginLoader ? (
                                    <svg viewBox="0 0 50 50" className="btn-loader-2">
                                        <path
                                            d="M50 25C50 11.2 38.8 0 25 0S0 11.2 0 25h4.2C4.2 13.6 13.5 4.2 25 4.2S45.8 13.6 45.8 25H50z"
                                            style={{ fill: "#fff" }}
                                        >
                                            <animateTransform
                                                accumulate="none"
                                                additive="replace"
                                                attributeName="transform"
                                                attributeType="XML"
                                                calcMode="linear"
                                                dur="1s"
                                                fill="remove"
                                                from="0 25 25"
                                                repeatCount="indefinite"
                                                restart="always"
                                                to="360 25 25"
                                                type="rotate"
                                            />
                                        </path>
                                    </svg>
                                ) : (
                                    "Verify"
                                )}
                            </button>
                            <button type="button" className="otp-login" onClick={() => otpLogin()}>
                                Use {!loginOTP ? "OTP" : "Password"} to Login
                            </button>
                        </div>
                    </form>
                </div>
            </div>
            <Modal
                open={passwordOpen}
                footer={null}
                closable={true}
                maskStyle={{ backgroundColor: "rgba(0,0,0,0.75)" }}
            >
                {!passwordReset && (
                    <OTPModalContent
                        onSubmit={verifyOTP}
                        isOpen={passwordOpen}
                        setIsOpen={setPasswordOpen}
                    />
                )}
                {passwordReset && (
                    <form
                        onSubmit={resetPasswordSubmit(resetPasswordSubmission)}
                        className="verifi-popup-wrap"
                        noValidate
                    >
                        <h2>Reset Password</h2>
                        <div className={errors && errors.password ? "inputHolder has-error" : "inputHolder"}>
                            <input
                                id="password"
                                type="password"
                                placeholder=" "
                                className="txt"
                                {...resetPasswordRegister("password", {
                                    minLength: { value: 8, message: "The password should have minimum 8 characters" },
                                })}
                                aria-label="New Password"
                            />
                            <label htmlFor="password" className="tag" aria-hidden>New Password</label>
                            <span className="form-error" aria-live="assertive" role="alert">
                                {(errors && errors.password) && <>{errors.password.message}</>}
                            </span>
                        </div>
                        <div className={errors && errors.cpassword ? "inputHolder has-error" : "inputHolder"}>
                            <input
                                id="cnfPassword"
                                type="password"
                                placeholder=" "
                                className="txt"
                                {...resetPasswordRegister("cpassword", {
                                    validate: (value) =>
                                        resetPasswordGetValues("password") === value || "Password doesn't match",
                                })}
                                aria-label="Confirm Password"
                            />
                            <label htmlFor="cnfPassword" className="tag" aria-hidden>Confirm Password</label>
                            <span className="form-error" aria-live="assertive" role="alert">
                                {(errors && errors.cpassword) && <>{errors.cpassword.message}</>}
                            </span>
                        </div>
                        <div className="inputHolder has-error" style={{ minHeight: "1px" }}>
                            <span className="form-error" aria-live="assertive" role="alert">
                                {samePasswordError && (
                                    <>Please provide a password that is not similar to your previous password</>
                                )}
                            </span>
                        </div>
                        <div className="inputHolder btn-wrap justify-content-center">
                            <button className="btn">Change Password</button>
                        </div>
                    </form>
                )}
            </Modal>
            <Modal
                open={loginOTPModal}
                footer={null}
                closable={false}
                maskStyle={{ backgroundColor: "rgba(0,0,0,0.75)" }}
            >
                <OTPModalContent
                    onSubmit={otpauth}
                    isOpen={loginOTPModal}
                    setIsOpen={setLoginOTPModal}
                />
            </Modal>
        </div>
    );
};

export default Login;